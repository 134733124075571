export default {
  debug: false,
  testing: false,
  auth0: {
    base: 'https://report.ipdc.org.au',
    clientId: '8VngkVb51gkkBZvitSordBN4l8rIOwet',
    domain: 'dev-nuzfq563.au.auth0.com',
    audience: "http://35.201.0.39/",
    returnTo: "https://report.ipdc.org.au"
  },
  apiBaseUrl: "https://report.ipdc.org.au"
};
